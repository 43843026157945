import { ArrayV2 } from "../../util/ArrayV2";
import { DataDRequest } from "../DRequest";
import { Entidad } from "../Entidad";
import { Global } from "../Global";
import { DataModuloMain } from "../ModuloMain";
import { RequestManagerLocalSave } from "../util/RequestManagerLocalSave";
import { DataUtil } from "../util/Util";
import DataModuloFinanzaHoraExtra from "./FinanzaHoraExtra";
import DataModuloGrado from "./Grado";
import DataModuloGrupo from "./Grupo";
import DataModuloHorarioAlumno from "./HorarioAlumno";
import DataModuloLogroAsignacion from "./LogroAsignacion";
import DataModuloTutor from "./Tutor";
import DataModuloTutorAsignacion from "./TutorAsignacion";

type IEscuela = Entidad.IEscuela;
type IAlumno = Entidad.IAlumno;

export const _DICC_ALUMNO: Map<number, Entidad.IAlumno> = new Map();

const REQUESTID = Entidad.CTipoRequest.Alumno;

export function _SvAlumnoObtenerLista(escuelas: IEscuela[]): Promise<DataDRequest.IRequestResponseListA<IAlumno>[]> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "alumno/ObtenerLista";
    return new ArrayV2<IEscuela>()
        ._Push(...escuelas)
        ._MapAwait(async ({ IdKinder }) => {
            const currentDateStr = await DataUtil._GetMaxDate(REQUESTID, IdKinder);
            const params = {
                "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
                "IdEscuela": IdKinder,
                "Modificacion": currentDateStr,
            }
            return DataDRequest._Request4List(url, "GET", params, REQUESTID, IdKinder);
        })
}

export async function _SvAlumnoNuevo(dato: Entidad.IAlumno, foto: string): Promise<DataDRequest.IResultadoPeticion<undefined>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "alumno/NuevoAlumno";

    const params = {
        "ApMaterno": dato.ApMaterno,
        "ApPaterno": dato.ApPaterno,
        "Nombre": dato.Nombre,
        "Matricula": (dato.Matricula ? dato.Matricula : ""),
        "FechaNacimiento": (dato.FechaNacimiento || null),
        "Sexo": (dato.Sexo ? dato.Sexo : Entidad.CSexo.Default),
        "IdGrado": dato.IdGrado,
        "IdKinder": dato.IdKinder,
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdEscolaridad": dato.IdEscolaridad,
        "Foto": (foto ? foto : null)
    };

    return new Promise(function (resolve, reject) {
        DataDRequest._Request(url, "POST", params, (result: { IdChild: number }) => {
            resolve({
                Resultado: (result?.IdChild || -100),
                TipoRequest: REQUESTID
            });
        });
    });
}

export async function _SvAlumnoEditar(dato: Entidad.IAlumno, activate = false): Promise<DataDRequest.IResultadoPeticion<undefined>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "alumno/EditarAlumno";

    const params = {
        "IdAlumno": dato.IdChild,
        "ApMaterno": dato.ApMaterno,
        "ApPaterno": dato.ApPaterno,
        "FechaNacimiento": (dato.FechaNacimiento || null),
        "IdEscolaridad": dato.IdEscolaridad,
        "IdGrado": dato.IdGrado,
        "IdEscuela": dato.IdKinder,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "Nombre": dato.Nombre,
        "Matricula": (dato.Matricula ? dato.Matricula : ""),
        "Sexo": (dato.Sexo ? dato.Sexo : Entidad.CSexo.Default),
        "EdoEscolar": dato.IdChildMovimiento || 0,
        "Activate": activate
    };

    return new Promise(function (resolve, reject) {
        DataDRequest._Request(url, "POST", params, (result: DataDRequest.IResultadoPeticion<null>) => {
            resolve({
                Resultado: result?.Resultado || -100,
                TipoRequest: REQUESTID
            });
        });
    });
}

export function _SvAlumnoActualizarFotoPerfil(idAlumno: number, idEscuela: number, imagenBase64: string): Promise<DataDRequest.IRequestResponseA> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "alumno/ActualizarFotoPerfil";
    const params = {
        "IdEscuela": idEscuela,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdAlumno": idAlumno,
        "Foto": imagenBase64
    }
    //"AdminJS/Child/ActualizarFotoPerfilV2";
    return DataDRequest._Request5(url, "POST", params, REQUESTID);
}

//--------------------------------------------------------------------------------
// /** @deprecated */
// export async function _SvAlumnoCambiarEstadoEscolar(idAlumno: number, estado: Entidad.CNinioMovimiento): Promise<DataDRequest.IResultadoPeticion<undefined>> {
//     const url = Global._GLOBAL_CONF.URL_RSTV1 + "Child/CambiarEstadoEscolarV2";
//     const params = {
//         IdChild: idAlumno,
//         IdUsuario: DataUtil._Usuario.IdUsuario,
//         IdMovimiento: estado
//     };

//     return new Promise(function (resolve, reject) {
//         DataDRequest._Request(url, "POST", params, (response: number) => {
//             resolve({
//                 Resultado: response ? response : -100,
//                 TipoRequest: REQUESTID
//             })
//         });
//     })
// }


//----------------------------------------------------------------------------------
export async function _SvAlumnoCargaMasiva(idEscuela: number, idGrado: number, idEscolaridad: number, datos: Entidad.IDataNinioCargaMasiva[]) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "alumno/CargaMasiva";
    const params = {
        "IdEscuela": idEscuela, // data.Utils.prop_Usuario.Idkinders[0],
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdGrado": idGrado,
        "IdEscolaridad": idEscolaridad,
        "Alumnos": datos,
    }

    return DataDRequest._Request5<undefined, { Alumnos: number[] }>(url, "POST", params, REQUESTID);
}

//----------------------------------------------------------------------------------------------------------------
// /** @deprecated */
// export async function fn_AsignacionTutor<DataDel, Data>(params: Data): Promise<data.DRequest.IResultadoPeticion<unknown>> {
//     let url = data.Utils.prop_URL_RSTV2 + "alumno/AgregarTutorAsignacion";
//     //ActualizaRelacionChildPadre";
//     return new Promise<any>(function (resolve, reject) {
//         DRequest.fn_Request(url, "POST", params, (result) => {
//             result = (result != null) ? result : { Resultado: -1 };
//             result.TipoRequest = RequestID;
//             resolve(result);
//         });
//     });
// }

// -------------------------------------------------------------------------------------------

export async function _SvAlumnoObtenerHistorialMovimiento(idAlumno: number, idEscuela: number): Promise<DataDRequest.IResultadoPeticion<Entidad.IMovimientoChild[]>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "alumno/ObtenerHistorialMovimiento";
    const params = {
        "IdAlumno": idAlumno,
        "IdKinder": idEscuela
    }

    return new Promise(function (resolve, reject) {
        DataDRequest._Request(url, "GET", params, (result: DataDRequest.IRequestResponseBase<Entidad.IMovimientoChild[]>) => {
            resolve({
                Resultado: (result?.Resultado || -100),
                Data: result?.Datos,
                TipoRequest: REQUESTID
            });
        });
    });
}

//-----------------------------------------------------------------------------------------------
// export async function fn_ObtenerUsuarioContrasenia<DataDel, Data>(params: Data): Promise<data.DRequest.IResultadoPeticion<unknown>> {
//     let url = data.Utils._GLOBAL_CONF.URL_RSTV2 + "alumno/ObtenerUsuarioContrasenia";

//     return new Promise<any>(function (resolve, reject) {
//         DRequest.fn_Request(url, "GET", params, (result) => {
//             result = (result != null) ? { Resultado: result.Resultado, Data: result["Datos"] } : { Resultado: -1 };
//             result.TipoRequest = RequestID;
//             //password
//             resolve(result);
//         })
//     })
// }

// ********************************************************************************
// EXPEDIENTES THINGS
// ********************************************************************************

export async function _SvAlumnoObtenerExpedientes(idEscuela: number, idAlumno: number): Promise<DataDRequest.IRequestResponseA<Entidad.IExpediente[]>> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "alumno/ObtenerExpedientes";
    const params = {
        "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
        "IdEscuela": idEscuela,
        "IdAlumno": idAlumno
    }
    return DataDRequest._Request5(url, "GET", params, REQUESTID);
}

export interface ISendExpedientes {
    IdEscuela: number;
    IdAlumno: number;
    TipoExpediente: Entidad.CTipoExpediente;
    Estado: number;
    Archivos: Array<{ File: File; Nombre: string; }>;
    Identificadores?: number[][];
}

export async function _SvAlumnoNuevosExpedientes(expedientes: ISendExpedientes): Promise<DataDRequest.IRequestResponseA> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "alumno/NuevosExpedientes";
    const formData = new FormData();
    formData.append("IdUsuario", DataUtil._Usuario.IdUsuario.toString());
    formData.append("IdOrganizacion", DataUtil._Usuario.IdOrganizacion.toString());
    formData.append("IdEscuela", expedientes.IdEscuela.toString());
    formData.append("IdAlumno", expedientes.IdAlumno.toString());
    formData.append("TipoExpediente", expedientes.TipoExpediente.toString());
    formData.append("Estado", expedientes.Estado.toString());
    if (expedientes.TipoExpediente == Entidad.CTipoExpediente.Boleta) {
        formData.append("Identificadores", JSON.stringify(expedientes.Identificadores))
    }

    for (let file of expedientes.Archivos) {
        formData.append("Archivos", file.File, file.Nombre)
    };

    return DataDRequest._Request3(url, "POST", formData, REQUESTID);
}

interface IExpedienteBoletaToSend {
    IdEscuela: number;
    IdAlumno: number;
    TipoExpediente: number;
    Estado: number;
    IdPadreBoleta: number;
    IdCicloEscolar: number;
    IdNivel: number;
    IdGrado: number;
    NInasistencias: number;
    IdsGrupos: number[];
    IdsEvaluaciones: number[];
    IdsMaterias: number[];
    IdsEvaluadores: string[];
    ObservacionTeachers: string;
    ObservacionTeachersFinal: string;
    ComentarioTutores: string;
    ComentarioTutoresFinal: string;
    NombreTutores: string;
    NombreTutoresFinal: string;
    Archivo: { Nombre: string, File: File }
}
export async function _SvAlumnoNuevoExpedienteBoleta(expedienteBoleta: IExpedienteBoletaToSend) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "alumno/NuevoExpedienteBoleta";
    const formData = new FormData();
    formData.append("IdUsuario", DataUtil._Usuario.IdUsuario.toString());
    formData.append("IdOrganizacion", DataUtil._Usuario.IdOrganizacion.toString());
    formData.append("IdEscuela", expedienteBoleta.IdEscuela.toString());
    formData.append("IdAlumno", expedienteBoleta.IdAlumno.toString());
    formData.append("TipoExpediente", expedienteBoleta.TipoExpediente.toString());
    formData.append("Estado", expedienteBoleta.Estado.toString());
    formData.append("IdPadreBoleta", expedienteBoleta.IdPadreBoleta.toString());
    formData.append("IdCicloEscolar", expedienteBoleta.IdCicloEscolar.toString());
    formData.append("IdNivel", expedienteBoleta.IdNivel.toString());
    formData.append("IdGrado", expedienteBoleta.IdGrado.toString());
    formData.append("NInasistencias", expedienteBoleta.NInasistencias.toString());
    formData.append("IdsGrupos", JSON.stringify(expedienteBoleta.IdsGrupos));
    formData.append("IdsEvaluaciones", JSON.stringify(expedienteBoleta.IdsEvaluaciones));
    formData.append("IdsMaterias", JSON.stringify(expedienteBoleta.IdsMaterias));
    formData.append("IdsEvaluadores", JSON.stringify(expedienteBoleta.IdsEvaluadores));
    formData.append("ObservacionTeachers", expedienteBoleta.ObservacionTeachers);
    formData.append("ObservacionTeachersFinal", expedienteBoleta.ObservacionTeachersFinal);
    formData.append("ComentarioTutores", expedienteBoleta.ComentarioTutores);
    formData.append("ComentarioTutoresFinal", expedienteBoleta.ComentarioTutoresFinal);
    formData.append("NombreTutores", expedienteBoleta.NombreTutores);
    formData.append("NombreTutoresFinal", expedienteBoleta.NombreTutoresFinal);
    formData.append("Archivo", expedienteBoleta.Archivo.File, expedienteBoleta.Archivo.Nombre);
    return DataDRequest._Request3(url, "POST", formData, REQUESTID);
}

// export async function _NuevoExpediente(params): Promise<DataDRequest.IResultadoPeticion<undefined>> {

//     return new Promise(function (resolve) {
//         console.log("params", params)
//         let url = Global._GLOBAL_CONF.URL_RSTV2 + "alumno/NuevosExpedientes";
//         let res: DataDRequest.IResultadoPeticion<undefined> = {
//             Resultado: -1,
//             TipoRequest: REQUESTID
//         }
//         // let item = { status: false, data: params };
//         var XHR = new XMLHttpRequest();
//         XHR.responseType = "arraybuffer";
//         XHR.open("POST", url);
//         XHR.send(params);
//         XHR.onload = function (oEvent) {
//             if (this.status == 200) {
//                 // item.status = true;
//                 res.Resultado = 1;
//                 resolve(res);
//             } else {
//                 resolve(res);
//             }
//         }
//         XHR.onerror = function (e) {
//             resolve(res);
//         }
//     });
// }

// // NOTE REVISAR
// export async function fn_ObtnerExpedienteArchivo<TParam>(idExpediente: number): Promise<DataDRequest.IResultadoPeticion<TParam>> {
//     // https://rst1-dev.kidi.mx/alumno/ObtenerExpedienteArchivo?IdOrganizacion=2&IdExpediente=12
//     const url = Util._GLOBAL_CONF.URL_RSTV2 + "alumno/ObtenerExpedienteArchivo";
//     const params = {
//         "IdOrganizacion": Util.prop_Usuario.IdOrganizacion,
//         "IdExpediente": idExpediente
//     }
//     return new Promise(function (resolve, reject) {
//         DRequest.fn_Request(url, "GET", params, (result: any) => {
//             let response: DataDRequest.IResultadoPeticion<TParam> = { Resultado: result != null ? 1 : -1 };
//             response.TipoRequest = RequestID;
//             response.Data = result;
//             resolve(response);
//         });
//     });
// }

export function _SvAlumnoURLObtenerFoto(alumno: Entidad.IAlumno | number, tamanio: 1 | 2 | 3) {
    if (typeof alumno == "number") {
        alumno = _DICC_ALUMNO.get(alumno);
    }
    if (alumno && alumno.FechaFoto) {
        return Global._GLOBAL_CONF.URL_RSTV2 + `alumno/ObtenerFoto?IdUsuario=${alumno.IdUsuario}&Tamanio=${tamanio}&Modificacion=${alumno.FechaFoto}`;
    }
    return null;
}

export function _SvAlumnoURLObtenerExpedienteArchivo(idExpediente: number) {
    return Global._GLOBAL_CONF.URL_RSTV2 + "alumno/ObtenerExpedienteArchivo?IdOrganizacion=" + DataUtil._Usuario.IdOrganizacion + "&IdExpediente=" + idExpediente;
}

// *************************************************************
// INFORMACIÓN EXTRA DE ALUMNOS
// *************************************************************

export function _SvAlumnoInfoExtraObtener(idAlumno: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "alumno/ObtenerInfoExtra";
    const params = {
        "IdAlumno": idAlumno
    }
    return DataDRequest._Request5<{ [k in string]: any }>(url, "GET", params, REQUESTID);
}

export function _SvAlumnoInasistenciasObtener(idAlumnos: number[], idEscuela: number, dtInit: string, dtFin: string) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "alumno/ObtenerInasistencias";
    const params = {
        "IdAlumnos": idAlumnos,
        "IdEscuela": idEscuela,
        "Inicio": dtInit,
        "Fin": dtFin
    }
    return DataDRequest._Request5<Entidad.IInasistenciasInfo[]>(url, "GET", params, REQUESTID)
}

export function _SvAlumnoActualizarInfoExtra(idEscuela: number, infoExtraData: Object) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "alumno/ActualizarInfoExtra";
    const params = {
        "IdUsuario": DataUtil._Usuario.IdUsuario,
        "IdAlumno": idEscuela,
        "InfoExtra": infoExtraData
    }
    return DataDRequest._Request5(url, "POST", params, REQUESTID);
}

// ********************************************************************************
// ASIGNACIONES MATERIAS
// ********************************************************************************

export async function _SvAlumnoObtenerMateriasDisponibles(idsAlumnos: number[], idEscuela: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "alumno/ObtenerMateriasDisponibles";
    const params = {
        IdsAlumnos: idsAlumnos.join(","),
        IdEscuela: idEscuela
    }

    return DataDRequest._Request5<Entidad.IMateriaDisponible[]>(url, "GET", params, REQUESTID);
}

export async function _SvAlumnoObtenerMateriasAsignadas(idEscuela: number, idAlumno: number, idCicloEscolar: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "alumno/ObtenerMateriasAsignadas";
    const params = {
        IdEscuela: idEscuela,
        IdAlumno: idAlumno,
        IdCicloEscolar: idCicloEscolar,
        Modificacion: null
    }
    return DataDRequest._Request5<Entidad.IAsignacionAlumnoMateria[]>(url, "GET", params, REQUESTID);
}

export async function _SvAlumnoObtenerCriteriosAsignados(idEscuela: number, idCicloEscolar: number, idsAlumnos: number[], idsGrupos: number[], idsMaterias: number[]) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "alumno/ObtenerCriteriosAsignados";
    const params = {
        IdEscuela: idEscuela,
        IdCicloEscolar: idCicloEscolar,
        IdsAlumnos: idsAlumnos,
        IdsGrupos: idsGrupos,
        IdsMaterias: idsMaterias
    }
    return DataDRequest._Request5<Entidad.ICriterioAsignado[]>(url, "GET", params, REQUESTID);
}

export async function _SvAlumnoObtenerCalificaciones(idAsignacion: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "alumno/ObtenerCalificaciones"
    const params = {
        IdAsignacion: idAsignacion
    }
    return DataDRequest._Request5<Entidad.IRepeticionAsignAlumnoMateria[]>(url, "GET", params, REQUESTID);
}

export async function _SvAlumnoGetBoletasEnCicloInfo(idsAlumnos: number[], idEscuela: number, idCicloEscolar: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "alumno/ObtenerInfoBoletasEnCicloV2";

    const params = {
        IdEscuela: idEscuela,
        IdAlumnos: idsAlumnos,
        IdCicloEscolar: idCicloEscolar,
        IdOrganizacion: DataUtil._Usuario.IdOrganizacion
    }

    return DataDRequest._Request5<Entidad.BoletasGeneradasInfo[]>(url, "GET", params, REQUESTID);
}

export async function _SvAlumnoObtenerListaNoticias(idAlumno: number) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "alumno/ObtenerListaNoticias";
    const params = {
        IdAlumno: idAlumno
    }
    return DataDRequest._Request5<Entidad.ICircularAlumno[]>(url, "GET", params, REQUESTID);
}

export interface AsignacionMateriaToSend {
    IdGrupo: number;
    IdMateria: number;
    IdConfiguracionMateria: number;
    Criterio?: string;
    FrecuenciaEval?: number;
    BloqueCalInicio: number;
    DiaCalInicio: number;
    DiasCalDuracion: number;
    Repeticiones: RepeticionAsig[];
}

interface RepeticionAsig {
    Inicio: string;
    Fin: string;
    CalificarInicio: string;
    CalificarFin: string;
}

interface IResultResolved extends IRequestAsignacionesMateriasResponse {
    ResolvedData: IDataResolvedMateriasAsign;
}

interface IDataResolvedItemMateriaAsign {
    IdAlumno: number;
    IdAsignacionP: number;
    IdCalificacion: number;
}

interface IDataResolvedMateriasAsign {
    /** Key -> IdAlumno*/
    [idAlumno: number]: IDataResolvedItemMateriaAsign[];
}
interface IRequestAsignacionesMateriasResponse {
    IdsAlumnos: number[];
    IdsAsignacionesP: number[];
    IdsCalificaciones: number[];
    Resultado: number;
}

export async function _SvAlumnoAsignarMaterias(idAlumnos: number[], asignaciones: AsignacionMateriaToSend[], idCicloEscolar: number): Promise<IResultResolved> {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "alumno/AsignarMaterias";
    const params = {
        IdsAlumnos: idAlumnos,
        IdUsuario: DataUtil._Usuario.IdUsuario,
        IdCicloEscolar: idCicloEscolar,
        Asignaciones: asignaciones
    }

    return new Promise((resolve, reject) => {
        DataDRequest._Request(url, "POST", params, ((result: IRequestAsignacionesMateriasResponse) => {
            let dataResolved = <IDataResolvedMateriasAsign>{};
            console.warn(result);
            if (result) {
                if (result.Resultado > 0) {
                    result.IdsAlumnos
                        .forEach((idAlumno, i) => {
                            if (dataResolved[idAlumno] == null) {
                                dataResolved[idAlumno] = [];
                            }

                            dataResolved[idAlumno]
                                .push({
                                    IdAlumno: result.IdsAlumnos[i], // IdAlumno
                                    IdAsignacionP: result.IdsAsignacionesP[i], // Asignaciones Padre
                                    IdCalificacion: result.IdsCalificaciones[i] // Repeticiones
                                })
                        });
                    console.debug("MateriaAsignación -> fn_InsertAsignaciónMateriaCriterio", result?.Resultado, [result.IdsAlumnos, result.IdsAsignacionesP, result.IdsCalificaciones], dataResolved)
                }
            }

            resolve({
                Resultado: (result?.Resultado || -100),
                IdsAlumnos: result?.IdsAlumnos,
                IdsAsignacionesP: result?.IdsAsignacionesP,
                IdsCalificaciones: result?.IdsCalificaciones,
                ResolvedData: dataResolved
            });
        }));
    });
}

export async function _SvAlumnoEliminarMateriasAsignadas(idsAsignaciones: number[]) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "alumno/EliminarMateriasAsignadas";
    const params = {
        IdUsuario: DataUtil._Usuario.IdUsuario,
        Ids: idsAsignaciones
    }
    return DataDRequest._Request5(url, "POST", params, REQUESTID);
}

type IEliminarCalifRes = { [idPadre: string]: number[] };

export async function _SvAlumnoEliminarCalificaciones(asignaciones: Object) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "alumno/EliminarCalificaciones";
    const params = {
        Asignaciones: asignaciones,
        IdUsuario: DataUtil._Usuario.IdUsuario
    }
    return DataDRequest._Request5<IEliminarCalifRes>(url, "POST", params, REQUESTID);
}

export async function _SvAlumnoObtenerBoletaCalificaciones(idAlumno: number, idCicloEscolar: number, inicio: string, fin: string) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "alumno/ObtenerBoletaCalificaciones";
    const params = {
        IdAlumno: idAlumno,
        IdCicloEscolar: idCicloEscolar,
        Inicio: inicio,
        Fin: fin
    }
    return DataDRequest._Request5<Entidad.IElementoCalificado[]>(url, "GET", params, REQUESTID);
}

export async function _SvAlumnoGetBoletaCalificacionesV2(idAlumno: number[], idCicloEscolar: number, periodos: string[]) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "alumno/ObtenerBoletaCalificacionesPorPeriodosV2";
    const params = {
        IdAlumno: idAlumno,
        IdCicloEscolar: idCicloEscolar,
        Periodos: periodos
    }

    return DataDRequest._Request5<Entidad.IElementoCalificado[]>(url, "GET", params, REQUESTID);
}

let reqBoletasManager: RequestManagerLocalSave<Entidad.IBoletaGeneradaInfo>;
export async function _SvAlumnoGetBoletasGeneradas(idEscuela: number, forceRequest = true) {
    if (!reqBoletasManager) {
        reqBoletasManager = new RequestManagerLocalSave<Entidad.IBoletaGeneradaInfo>();
    }
    if (!forceRequest) {
        return {
            Resultado: 1,
            Datos: reqBoletasManager._GetDataArrayEnUso(idEscuela) || [],
        }
    }
    return reqBoletasManager
        ._SetServiceURL(Global._GLOBAL_CONF.URL_RSTV2 + "alumno/ObtenerBoletasGeneradas")
        ._SetDefaultParams(() => ({
            "IdOrganizacion": DataUtil._Usuario.IdOrganizacion,
            "IdEscuela": idEscuela,
            "Modificacion": reqBoletasManager._GetLastRequestResponse(idEscuela)?.Maxima || null,
        }))
        ._SetRequestProccess((url, params) => DataDRequest._Request4List<any, Entidad.IBoletaGeneradaInfo>(url, "GET", params))
        ._DoRequest(idEscuela, "Id")
}

export interface IFotoEvento {
    DtEvento: string,
    Id: number;
}

export async function _SvAlumnoObtenerFotosMes(idEscuela: number, idAlumno: number, inicio: string, fin: string) {
    const url = Global._GLOBAL_CONF.URL_RSTV2 + "alumno/ObtenerFotosMes";
    const params = {
        IdEscuela: idEscuela,
        IdAlumno: idAlumno,
        Inicio: inicio,
        Fin: fin
    }

    return DataDRequest._Request5<IFotoEvento[]>(url, "GET", params, REQUESTID);
}


// ********************************************************************************
// LOCAL DATA
// ********************************************************************************

/**
 * 
 * @param idAlumno 
 * @param idEscuela La función es propensa a retornar Asignaciones del Alumno con Horarios de otras escuelas (en caso de que éstas asignaciones no hayan sido eliminadas (motivos X)).
 * En caso de omitir el parámetro, se retornan todas las asignaciones del alumno.
 * @param includeGroupsNoFounded  Retornar incluso cuando el grupo del Horario no haya sido encontrado.
 * Los campos que retornan undefined son "Nombre", "GrupoGradoNombre", "GrupoEscolaridadNombre", "GrupoEscuelaNombre" y "EsPrincipal"
 * @default false
 * @returns Map<IdGrupo, Horario(Dias: Entradas y Salidas)>
 */
export function _LOCALDATA_GetGruposHorariosDeAlumno(idAlumno: number, idEscuela?: number, includeGroupsNoFounded = false): Map<number, Entidad.IAlumnoHorarioGrupoAsignacion> {
    let gruposHorarios: Map<number, Entidad.IAlumnoHorarioGrupoAsignacion> = new Map();
    let horariosGruposMap = DataModuloHorarioAlumno._DiccAsignacionAlumnoGruposHorarios.get(idAlumno);

    horariosGruposMap?.forEach(asignacionH => {
        if (idEscuela && (asignacionH.IdKinder != idEscuela)) {
            return;
        }
        let itemH: Entidad.IAlumnoHorarioGrupoAsignacion = {
            ...Object.assign({}, asignacionH),
            ...{
                Nombre: undefined,
                EsPrincipal: undefined,
                IdGrado: undefined
            }
        }

        let grupo = DataModuloGrupo._DiccGrupo.get(asignacionH.IdGrupo);
        if (grupo) {
            let grado = DataModuloGrado._DiccGrado.get(grupo.IdNivel);
            itemH.Nombre = grupo.Nombre;
            itemH.IdGrado = grupo?.IdNivel;
            itemH.GrupoGradoNombre = grado?.Nombre;
            itemH.GrupoEscolaridadNombre = grado ? DataModuloMain._GetReqDataMapByName("Escolaridad").get(grado.IdEscolaridad)?.Nombre : undefined;
            itemH.GrupoEscuelaNombre = grado ? DataModuloMain._GetReqDataMapByName("Escuela").get(grado.IdKinder)?.Nombre : undefined;
            itemH.EsPrincipal = grupo.EsPrincipal;
            gruposHorarios.set(asignacionH.IdGrupo, itemH);
        } else {
            if (includeGroupsNoFounded) {
                gruposHorarios.set(asignacionH.IdGrupo, itemH);
            }
            // console.warn("-d", `>> LOCALDATA -> Grupo no encontrado: ${asignacionH.IdGrupo}, Alumno: ${idAlumno}`);
        }
    });

    return gruposHorarios;
}

interface IAsignacionesTutoresRespuesta {
    Tutor: Entidad.ITutor;
    Asignacion: Entidad.ITutorAsignacion;
}

export function _LOCALDATA_GetAsignacionesTutoresDeAlumno(idAlumno: number, includeTutorsNoFounded: boolean = false): Map<number, IAsignacionesTutoresRespuesta> {
    const asignacionesMap = DataModuloTutorAsignacion._DiccAsignacionesPorAlumno.get(idAlumno);

    if (asignacionesMap) {
        const tutoresArrays: readonly [number, IAsignacionesTutoresRespuesta][] = Array.from(asignacionesMap.values())
            .map<[number, IAsignacionesTutoresRespuesta]>(asignacion => {
                const tutor = DataModuloTutor._DiccTutores.get(asignacion.IdPadre);
                if (!tutor) {
                    // console.warn("-d", `>> LOCALDATA -> Tutor no encontrado: ${asignacion.IdPadre}, Alumno: ${idAlumno}`);
                }
                return ([
                    asignacion.IdPadre,
                    {
                        Tutor: tutor,
                        Asignacion: asignacion
                    }
                ])
            });

        if (includeTutorsNoFounded) {
            return new Map(tutoresArrays);
        } else {
            return new Map(tutoresArrays.filter(d => Boolean(d[1].Tutor)));
        }
    }
    return new Map();
}

export function _LOCALDATA_GetTutoresDeAlumno(idAlumno: number, includeTutorsNoFounded: boolean = false): Map<number, Entidad.ITutor> {
    const asignacionesTutoresMap = _LOCALDATA_GetAsignacionesTutoresDeAlumno(idAlumno, includeTutorsNoFounded);
    const asignacionesTutoresArray = Array.from(asignacionesTutoresMap.values());

    return new Map(asignacionesTutoresArray.map(d => ([d.Asignacion.IdPadre, d.Tutor])));
}

/** @returns Map<IdAsignacion, ILogroAsignacion>> */
export function _LOCALDATA_GetLogroAsignacionesDeAlumno(idAlumno: number): Map<number, Entidad.ILogroAsignacion> {
    return DataModuloLogroAsignacion._DiccAlumnosAsignaciones.get(idAlumno) || new Map();
}

/** @returns Map<IdLogro, ILogro>> */
export function _LOCALDATA_GetLogrosDeAlumno(idAlumno: number, includeLogrosNoFounded = false): Map<number, Entidad.ILogro> {
    let logrosArray: [number, Entidad.ILogro][] = Array.from(_LOCALDATA_GetLogroAsignacionesDeAlumno(idAlumno).values())
        .map(d => ([
            d.IdLogro,
            DataModuloMain._GetReqDataMapByName("Logro").get(d.IdLogro),
        ]));

    if (!includeLogrosNoFounded) {
        logrosArray = logrosArray.filter(d => Boolean(d[1]));
    }

    return new Map(logrosArray);
}

export function _LOCALDATA_GetHermanos(idAlumno: number, includeStudentsNoFounded: boolean = false): Map<number, Entidad.IAlumno> {
    const asignacionesMap: Map<number, Entidad.ITutorAsignacion> = DataModuloTutorAsignacion._DiccAsignacionesPorAlumno.get(idAlumno) || new Map();
    const hermanosMap = new Map<number, Entidad.IAlumno>();

    asignacionesMap.forEach(asignacion => {
        if (!asignacion.EsMonitoreo) {
            DataModuloTutor._LOCALDATA_GetAsignacionesAlumnos(asignacion.IdPadre, "no-monitoreo", includeStudentsNoFounded)
                .forEach(hermano => {
                    if (hermano.Asignacion.IdChild != idAlumno) {
                        hermanosMap.set(hermano.Asignacion.IdChild, hermano.Alumno);
                    }
                })
        }
    })

    return hermanosMap;
}

export function _LOCALDATA_GetAsignacionesHorasExtras(idAlumno: number): Map<number, Entidad.IFinanzaItemHoraExtra> {
    return DataModuloFinanzaHoraExtra._DiccHorasExtrasByAlumno.get(idAlumno) || new Map();
}
